<template>
  <div>
    <div
      class="helper-start-popup__overlay"
    ></div>
    <div class="helper-start-popup">
      <div class="helper-start-popup__title" v-html="text.title">Добро пожаловать на AZ-MOST!</div>
      <div class="helper-start-popup__description" v-html="text.description">
        На портале представлена экспертная информация о заболеваниях,
        современных подходах к терапии и многое другое
      </div>

      <div class="helper-start-popup__buttons">
        <div
          class="helper-start-popup__button button button_empty-gray"
          @click="$emit('closeHelp', true)"
        >
          Пропустить
        </div>
        <div
          class="helper-start-popup__button button button_pink"
          @click="$emit('startHelp')"
        >
          Начать обучение
          <svg
            class="ml-2"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 4.5L16.5 12L9 19.5"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="square"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelperStart",
  props: {
    text: Object
  }
};
</script>

<style lang="scss" scoped>
.helper-start-popup {
  // position: absolute;
  // top: 100px;
  // right: 0;
  width: 450px;
  padding: 24px 20px 0;
  background-color: #fff;
  overflow: hidden;
  border-top: 8px solid #830051;
  box-shadow: 0px 4px 12px rgba(87, 0, 54, 0.1);

  position: fixed;
  z-index: 9999999;
  top: 125px;
  left: 50%;
  transform: translateX(-50%);
  @media screen and (max-width: 1220px) {
    right: 15px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    right: 0;
    top: 61px;
  }

  // @media screen and (max-width: 1220px) {
  //   top: calc(100% + 1px);
  // }

  // @media screen and (max-width: 767px) {
  //   width: 100%;
  //   top: calc(100% + 11px);
  // }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: rgba(31, 31, 31, 0.6);
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 100;
  }

  &__title {
    margin-bottom: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #1f1f1f;
  }

  &__buttons {
    margin: 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>